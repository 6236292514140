import React from 'react'

const Heading = ({children, className="text-center"}) => {
  return (
    <div className={`heading-wrapper ${className}`}>
        {children}
    </div>
  )
}

export default Heading